import axios from 'axios';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';

import DPCoin from '../components/DPCoin';
import Overlay from '../components/Overlay';
import TaskItem from '../components/TaskItem';
import SlopeBox from '../components/SlopeBox';
import Walkthrough from '../components/Walkthrough';
import ConfirmModal from '../components/ConfirmModal';
import ChallengeItem from '../components/ChallengeItem';
import PointsSection from '../components/PointsSection';
import LoadingOverlay from '../components/LoadingOverlay';
import LanguageSelector from '../components/LanguageSelector';
import { fetchUserData, registerUser } from '../utils/user';

import '../assets/styles/Bonus.css';

const API_URL = import.meta.env.VITE_API_URL;

const claimWelcomeBonus = async (tg_id) => {
  const response = await axios.post(`${API_URL}/users/claim-points`, { tg_id });
  return response.data;
};

const Bonus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tonConnectUI] = useTonConnectUI();
  const tonAddress = useTonAddress();
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const auth = useSelector((state) => state.auth);
  const tg_id = auth.tg.id;
  const tg_username = auth.tg.username;
  const tg_first_name = auth.tg.first_name;
  const user_id = auth._id;
  const hasPoints = auth.points !== 0;
  const devMode = auth.devMode;

  const [activeTab, setActiveTab] = useState('daily');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClaimBonus = async () => {
    try {
      await claimWelcomeBonus(tg_id);

      toast.success(t('Welcome Bonus claimed!'));

      await fetchUserData(dispatch, tg_id);
    } catch (error) {
      console.error('Error claiming bonus:', error);
    }
  };

  const fetchEarnables = async () => {
    const response = await axios.get(`${API_URL}/earnables/${tg_id}`);
    fetchUserData(dispatch, tg_id);
    return response.data;
  };

  const {
    data: earnablesData,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(['earnables', tg_id], fetchEarnables, {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  const handleClaim = async (earnableId) => {
    try {
      await axios.post(`${API_URL}/earnables/claim`, {
        tg_id,
        earnableId,
      });
      toast.success(t('Earnable claimed successfully!'));
      refetch();
    } catch (error) {
      console.error('Error claiming earnable:', error);
      toast.error('Error Claiming earnable');
    }
  };

  const handleRegister = async () => {
    try {
      const user = window.Telegram.WebApp.initDataUnsafe.user;
      await registerUser(user);
      await fetchUserData(dispatch, user.id);
      toast.success('Registered Successfully!');
      refetch();
    } catch (error) {
      toast.error('Error Registering', error.message);
    }
  };

  const handleDisconnect = () => {
    setIsModalOpen(true);
  };

  const confirmDisconnect = async () => {
    setIsModalOpen(false);
    await tonConnectUI.disconnect();
    toast.success('Wallet disconnected successfully');
  };

  const cancelDisconnect = () => {
    setIsModalOpen(false);
  };

  const oneTimeTasks =
    earnablesData?.earnables?.filter((e) => e.type === 'task' && !e.highlight) || [];
  const dailyTasks =
    earnablesData?.earnables?.filter((e) => e.type === 'daily' || e.highlight) || [];
  const challenges =
    earnablesData?.earnables
      ?.filter((e) => e.type === 'challenge')
      ?.sort((a, b) => {
        if (a.condition.type === 'invite' && b.condition.type !== 'invite') return -1;
        if (a.condition.type !== 'invite' && b.condition.type === 'invite') return 1;
        if (a.isCompleted && !b.isCompleted) return 1;
        if (!a.isCompleted && b.isCompleted) return -1;
        return 0;
      }) || [];

  if (!user_id) {
    return (
      <Overlay
        title={
          tg_username
            ? t("Let's Register You, {{username}}!", { username: tg_username })
            : t("Let's Register You!")
        }
        message={t('Press the button below to register your account and start earning')}
        onRegister={handleRegister}
      />
    );
  }

  const joyrideBonusSteps = [
    {
      target: 'body',
      route: '/bonus',
      placement: 'center',
      title: `${t('Welcome to Dashpool')}, ${tg_first_name || tg_username}!`,
      content: (
        <>
          {t('Your journey with Aikido starts here!')}
          <br />
          <br />
          {t('Get ready to contribute, earn rewards, and explore the world of AI.')}
          <br />
          <br />
          {t('Let’s begin by setting up the basics.')}{' '}
          {!hasPoints && (
            <>
              {t('And don’t forget to claim your')}{' '}
              <span className="text-accent-primary">{t('welcome bonus!')}</span>
            </>
          )}
          <br />
          <br />
          {!hasPoints && (
            <button className="btn-primary btn-md" onClick={handleClaimBonus}>
              {t('Claim Welcome Bonus')}
            </button>
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: !hasPoints,
    },
    {
      target: '.header',
      route: '/bonus',
      placement: 'center',
      title: t('Choose Your Language'),
      content: (
        <>
          {t('Let’s make sure you get the best experience by selecting your preferred language.')}
          <br />
          <br />
          {t('Pick from the list below:')}
          <br />
          <br />
          <LanguageSelector />
        </>
      ),
      disableBeacon: true,
    },
    {
      target: '.tasks-section',
      route: '/bonus',
      placement: 'bottom',
      title: t('Extra Tasks = Extra Rewards!'),
      content: (
        <>
          {t(
            'Here, you’ll find various tasks to increase your points. Complete them daily to keep leveling up and earning more rewards.'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#level-icon',
      route: '/bonus',
      placement: 'bottom',
      title: t('Boost Your Level, Unlock More!'),
      content: (
        <>
          {t(
            'The higher your level, the more features and rewards you unlock. Complete tasks to level up and climb higher.'
          )}
        </>
      ),
      disableBeacon: true,
      // spotlightClicks: true,
    },
    {
      target: '#snapmatch-button',
      route: '/bonus',
      placement: 'bottom',
      title: t('Let’s kick things off with SnapMatch'),
      content: (
        <>
          {t(
            'Let’s kick things off with one of our coolest features, SnapMatch. You’ll be earning in no time!'
          )}
          <br />
          <br />
          {t(
            'Start with 1000 Energy, and get 1 energy back every 10 seconds. Each SnapMatch uses 50 Energy.'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true,
    },
  ];

  useEffect(() => {
    const bonusWalkthrough = localStorage.getItem('bonusWalkthrough') === null;
    if (bonusWalkthrough) {
      localStorage.setItem('bonusWalkthrough', 'false');
      setShowWalkthrough(true);
    }
  }, []);

  return (
    <div className="earn-container">
      {isLoading && <LoadingOverlay />}
      {showWalkthrough && <Walkthrough key="bonus" steps={joyrideBonusSteps} place="bonus" />}
      <div className="earn-header">
        <h1>{t('Bonus').toUpperCase()}</h1>
        <p>{t('Earn More Points')}</p>
      </div>

      <div className="earn-body">
        <PointsSection
          points={earnablesData?.points || 0}
          distanceDriven={earnablesData?.distanceDriven || 0}
        />
        {!tonAddress && (
          <div className="dev-mode-section">
            <TonConnectButton />
          </div>
        )}
        {tonAddress && (
          <div className="wallet-address-section">
            <SlopeBox highlight>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <p>
                  {t('Wallet Connected')} <br />
                  {tonAddress ? `${tonAddress.slice(0, 4)}...${tonAddress.slice(-4)}` : ''}
                </p>
                <FaTimes className="disconnect-icon" onClick={handleDisconnect} />
              </div>
            </SlopeBox>
          </div>
        )}
        <ConfirmModal
          isOpen={isModalOpen}
          onClose={cancelDisconnect}
          onConfirm={confirmDisconnect}
          title={t('Disconnect Wallet')}
          message={t('Are you sure you want to disconnect your wallet?')}
        />
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'daily' ? 'active' : ''}`}
            onClick={() => setActiveTab('daily')}
          >
            {t('Daily tasks')}
          </button>
          <button
            className={`tab-button ${activeTab === 'tasks' ? 'active' : ''}`}
            onClick={() => setActiveTab('tasks')}
          >
            {t('Tasks')}
          </button>
          <button
            className={`tab-button ${activeTab === 'challenges' ? 'active' : ''}`}
            onClick={() => setActiveTab('challenges')}
          >
            {t('Challenges')}
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'daily' && (
            <div className="tasks-section">
              {dailyTasks.length > 0 ? (
                dailyTasks
                  .sort((a, b) => {
                    if (a.title === 'Daily Login Reward') return -1;
                    if (b.title === 'Daily Login Reward') return 1;

                    const timestampA = parseInt(a._id.substring(0, 8), 16);
                    const timestampB = parseInt(b._id.substring(0, 8), 16);

                    return timestampB - timestampA;
                  })
                  .map((task) => (
                    <TaskItem
                      key={task._id}
                      id={task._id}
                      icon={
                        task.title.includes('Soarchain')
                          ? '/icon_soarchain_white.svg'
                          : task.icon || '/icon_calendar.svg'
                      }
                      title={task.title}
                      points={
                        task.frequency === 'daily' ? (
                          <div className="task-item-daily-points">
                            <img src="/icon_aikido_coin.svg" alt={t('Coin')} />
                            <p>{`${task.points} ${t('now')}, ${task.points + 1} ${t(
                              'tomorrow'
                            )}!`}</p>
                          </div>
                        ) : (
                          <DPCoin points={task.points} />
                        )
                      }
                      condition={task.condition}
                      isCompleted={task.isCompleted}
                      highlight={task.highlight}
                      frequency={task.frequency}
                      bonus={task.bonus}
                      onClaim={handleClaim}
                      daily
                    />
                  ))
              ) : (
                <div className="card">
                  <p>{t("You've already done everything for today!")}</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'tasks' && (
            <div className="tasks-section">
              {oneTimeTasks.length > 0 ? (
                oneTimeTasks
                  .sort((a, b) => {
                    const timestampA = parseInt(a._id.substring(0, 8), 16);
                    const timestampB = parseInt(b._id.substring(0, 8), 16);

                    return timestampB - timestampA;
                  })
                  .map((task) => (
                    <TaskItem
                      key={task._id}
                      id={task._id}
                      title={task.title}
                      icon={
                        task.title.includes('Soarchain')
                          ? '/icon_soarchain_white.svg'
                          : task.icon || '/icon_daily_reward.webp'
                      }
                      condition={task.condition}
                      points={`+${task.points}`}
                      isCompleted={task.isCompleted}
                      highlight={task.highlight}
                      onClaim={handleClaim}
                    />
                  ))
              ) : (
                <div className="card">
                  <p>{t("You've already done everything for today!")}</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'challenges' && (
            <div className="challenges-section">
              {challenges.length > 0 ? (
                challenges.map((challenge) => (
                  <ChallengeItem
                    key={challenge._id}
                    id={challenge._id}
                    title={challenge.title}
                    points={`+${challenge.points}`}
                    progress={challenge.progress}
                    condition={challenge.condition}
                    isCompleted={challenge.isCompleted}
                    onClaim={handleClaim}
                  />
                ))
              ) : (
                <div className="card">
                  <p>{t("You've already passed all the challenges!")}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isError && (
        <div className="error-message">
          {t('Error')}: {error.message}
        </div>
      )}
    </div>
  );
};

export default Bonus;
