import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import '../assets/styles/PopUp.css';
import SlopeButton from './SlopeButton';

const PopUp = ({ popUp, onClose }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { buttons = [] } = popUp;

  const currentLanguage = i18n.language || 'en';

  const title =
    (typeof popUp.title === 'object' ? popUp.title[currentLanguage] : popUp.title) ||
    popUp.title['en'];

  const content =
    (typeof popUp.content === 'object' ? popUp.content[currentLanguage] : popUp.content) ||
    popUp.content['en'];

  const handleButtonClick = (action) => {
    if (action.startsWith('http')) {
      window.open(action, '_blank');
    } else if (action.startsWith('/')) {
      navigate(action);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <button className="popup-close" onClick={onClose}>
          &times;
        </button>
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="popup-buttons">
          {buttons.map((button, index) => (
            <SlopeButton
              size="sm"
              fullWidth
              key={index}
              onClick={() => handleButtonClick(button.action)}
            >
              {button.label}
            </SlopeButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopUp;
