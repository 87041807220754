import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import PopUp from './PopUp';

const PopUpManager = () => {
  const config = useSelector((state) => state.config);
  const [currentPopUp, setCurrentPopUp] = useState(null);

  useEffect(() => {
    if (config && config.pop && config.pop.length > 0) {
      const seenPopUps = JSON.parse(localStorage.getItem('seenPopUps') || '[]');
      const unseenPopUps = config.pop
        .filter((pop) => !seenPopUps.includes(pop.id))
        .sort((a, b) => b.priority - a.priority);

      if (unseenPopUps.length > 0) {
        setCurrentPopUp(unseenPopUps[0]);
      }
    }
  }, [config]);

  const handlePopUpClose = (id) => {
    const seenPopUps = JSON.parse(localStorage.getItem('seenPopUps') || '[]');
    seenPopUps.push(id);
    localStorage.setItem('seenPopUps', JSON.stringify(seenPopUps));

    setCurrentPopUp(null);
  };

  if (!currentPopUp) {
    return null;
  }

  const bonusWalkthrough = localStorage.getItem('bonusWalkthrough') === null;
  if (bonusWalkthrough) return null;

  return <PopUp popUp={currentPopUp} onClose={() => handlePopUpClose(currentPopUp.id)} />;
};

export default PopUpManager;
