import axios from 'axios';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward, IoIosCopy } from 'react-icons/io';

import DPCoin from '../components/DPCoin';
import SlopeButton from '../components/SlopeButton';
import Walkthrough from '../components/Walkthrough';
import LoadingOverlay from '../components/LoadingOverlay';

import '../assets/styles/Friends.css';

const API_URL = import.meta.env.VITE_API_URL;

const fetchInvitees = async ({ queryKey }) => {
  const [_, tg_id, page, limit] = queryKey;
  const response = await axios.get(`${API_URL}/users/user/${tg_id}/friends`, {
    params: { page, limit },
  });

  return response.data;
};

const Friends = () => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const { maintenance, highLoad } = useSelector((state) => state.config);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const tg_id = auth.tg.id;
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const { data, isLoading, isError, error } = useQuery(
    ['friends', tg_id, page, limit],
    fetchInvitees,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5,
      enabled: !maintenance && !highLoad,
    }
  );

  const inviteLink = `https://t.me/DashPoolBot/?start=invite=${tg_id}`;

  const handleNextPage = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const shareInviteLink = () => {
    window.Telegram.WebApp.openTelegramLink(
      `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}`
    );
  };

  const copyInviteLink = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(inviteLink)
        .then(() => {
          toast.success(t('Invite link copied to clipboard.'));
        })
        .catch((error) => {
          console.error('Failed to copy invite link:', error);
          toast.error(t('Failed to copy invite link.'));
        });
    } else {
      toast.error(t('Clipboard not supported.'));
    }
  };

  const openCommunity = () => {
    window.Telegram.WebApp.openTelegramLink(`https://t.me/+-UzzeSSv415mMWU0`);
  };

  if (maintenance) {
    return (
      <div className="friends-container">
        <div className="friends-header">
          <h1>{t('Friends')}</h1>
        </div>

        <div className="friends-body">
          <div className="announcement-banner">
            <img src="/maintenance.webp" alt={t('maintenance')} />
            <h1>{t("We'll Be Back Soon!")}</h1>
            <p>{t('Friends page is currently down for maintenance. Please check back shortly.')}</p>
          </div>
        </div>
      </div>
    );
  }

  if (highLoad) {
    return (
      <div className="friends-container">
        <div className="friends-header">
          <h1>{t('Friends')}</h1>
        </div>

        <div className="friends-body">
          <div className="announcement-banner">
            <img src="/icon_clock.svg" alt={t('high load')} />
            <h1>{t("We're Onboarding New Users!")}</h1>
            <p>{t('Friends page will be available soon after the new users are onboarded.')}</p>
          </div>
        </div>
      </div>
    );
  }

  const joyrideFriendsSteps = [
    {
      target: '#invite-friends-button',
      route: '/friends',
      placement: 'bottom',
      title: t('Invite Your Friends!'),
      content: (
        <>
          {t(
            'Invite friends to Aikido and build your squad to earn extra rewards and share them with your team!'
          )}
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: 'body',
      route: '/friends',
      placement: 'center',
      title: t('What would you like to do next?'),
      hideFooter: true,
      content: (
        <div
          style={{
            display: 'grid',
            gap: '12px',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(3, auto)',
          }}
        >
          <Link className="btn-primary btn-sm" to="/snapmatch-intro">
            {t('SnapMatch')}
          </Link>
          <Link className="btn-primary btn-sm" to="/bonus">
            {t('Check Tasks')}
          </Link>
          <Link className="btn-primary btn-sm" to="/map">
            {t('Explore Map')}
          </Link>
          <button className="btn-primary btn-sm" onClick={openCommunity}>
            {t('Community')}
          </button>
          <Link className="btn-primary btn-sm" to="/camera">
            {t('DashPool')}
          </Link>
          <button className="btn-primary btn-sm" onClick={shareInviteLink}>
            {t('Invite Friends')}
          </button>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const friendsWalkthrough = localStorage.getItem('friendsWalkthrough') === null;
    if (friendsWalkthrough) {
      localStorage.setItem('friendsWalkthrough', 'false');
      setShowWalkthrough(true);
    }
  }, []);

  return (
    <div className="friends-container">
      {showWalkthrough && <Walkthrough key="friends" steps={joyrideFriendsSteps} place="friends" />}
      <div className="friends-header">
        <h1>{t('Friends')}</h1>
        <p>{t('List of your friends')}</p>

        <div className="invite-buttons">
          <SlopeButton id="invite-friends-button" highlight size="sm" onClick={shareInviteLink}>
            <div className="btn-invite">
              <h2>{t('Invite Friends')}</h2>
              <div className="btn-invite-rewards">
                <img src="/icon_aikido_coin.svg" alt={t('Coin')} width={'24px'} />
                <h3>{data?.bonus ? `+${data.bonus.reward}` : '+100'}</h3>
              </div>
            </div>
          </SlopeButton>
          <SlopeButton id="copy-invite-link-button" size="sm" onClick={copyInviteLink}>
            <div className="btn-copy">
              <IoIosCopy size={20} />
            </div>
          </SlopeButton>
        </div>
      </div>

      <div className="friends-body">
        {isError && (
          <div>
            {t('Error')}: {error?.message}
          </div>
        )}

        {data?.bonus && !isLoading && (
          <div className="bonus-info top-left-box" onClick={shareInviteLink}>
            <p>{data?.bonus?.message}</p>
          </div>
        )}

        <p className="invitees-list-title">
          {t('List of your friends')}{' '}
          <span className="text-accent-primary">{data?.totalFriends || 0}</span>
        </p>
        <div className="invitees-list">
          {isLoading ? (
            <LoadingOverlay />
          ) : data?.friends?.length > 0 ? (
            data.friends.map((invitee, index) => (
              <div key={index} className="invitee-item">
                <p>
                  <span>{index + 1 + (page - 1) * limit}.</span>{' '}
                  <strong>
                    {invitee?.tg?.first_name?.slice(0, 20)}
                    {invitee?.tg?.first_name?.length > 20 ? '...' : ''}
                  </strong>
                </p>
                <div dir="ltr" className="invitee-item-stats">
                  <DPCoin points={invitee.points?.toFixed(0) || 0} />
                </div>
              </div>
            ))
          ) : (
            <p>{t('No friends yet. Start inviting friends!')}</p>
          )}
        </div>

        {data?.totalFriends > limit && (
          <div className="pagination-controls">
            <button onClick={handlePreviousPage} disabled={page === 1}>
              <IoIosArrowBack size={15} />
            </button>
            <span>
              {t('Page')} {page} {t('of')} {data?.totalPages}
            </span>
            <button onClick={handleNextPage} disabled={page === data?.totalPages}>
              <IoIosArrowForward size={15} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Friends;
